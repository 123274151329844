<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white fixed md:static"
		>
			<span class="ml-2 font-bold">Productos</span>
			<div class="flex items-center justify-center">
				<router-link to="/AgregarProducto"
					><button
						@click="$emit('click')"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="underline ml-2">Agregar Producto</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="flex items-center content-center w-full mt-20 md:mt-2">
			<table id="cotizar" class="table-auto mx-auto">
				<thead>
					<tr>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'id'">
							ID <span v-if="productoFiltro === 'id'">&nabla;</span>
						</th>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'rubro'">
							Rubro <span v-if="productoFiltro === 'rubro'">&nabla;</span>
						</th>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'producto'">
							Producto<span v-if="productoFiltro === 'producto'"> &nabla;</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(producto, k) in productosTotal" :key="k">
						<td class="border-2 px-4 py-4">
							<div class=" w-auto focus:outline-none text-center" type="text">
								<router-link
									class="hover:text-blue-800 hover:underline"
									:to="{
										name: 'EditarProducto',
										params: { id: producto.id.padStart(4, '0') }
									}"
									>{{ producto.id.padStart(4, "0") }}</router-link
								>
							</div>
						</td>
						<td class="border-2 px-4 py-4">
							{{
								producto.rubro.charAt(0).toUpperCase() + producto.rubro.slice(1)
							}}
						</td>
						<td class="border-2 px-4 py-4">
							{{
								producto.producto.charAt(0).toUpperCase() +
									producto.producto.slice(1)
							}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-between mx-16">
			<div>
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="inicioData > 0"
					@click="loadLess()"
				>
					Anterior
				</button>
			</div>
			<div>
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul ml-30"
					v-if="continuaData"
					@click="loadMore()"
				>
					Siguiente
				</button>
			</div>
		</div>
		<div class="flex items-center justify-center w-full my-10">
			<router-link to="AgregarProducto"
				><Botonp class="underline" botonpText="Añadir producto">
					<svg
						id="Capa_1"
						enable-background="new 0 0 551.13 551.13"
						height="512"
						viewBox="0 0 551.13 551.13"
						width="512"
						class="h-6 w-6 mr-2 fill-current text-botonT"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
						/>
						<path
							d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
						/>
					</svg> </Botonp
			></router-link>
		</div>
	</div>
</template>

<script>
import Botonp from "@/components/botonPrimario";

export default {
	name: "Productos",
	components: {
		Botonp
	},
	data: function() {
		return {
			productoFiltro: "id",
			inicioData: 0,
			finData: 0,
			paginacion: 200,
			productosQuery: {},
			productosTotal: [],
			continuaData: false
		};
	},
	created() {
		this.finData = this.paginacion;
	},
	apollo: {
		productosQuery: {
			query: require("@/graphql/queries/productosTotal.gql"),
			// Reactive parameters
			variables() {
				// Use vue reactive properties here
				return {
					filtro: this.productoFiltro,
					inicio: this.inicioData,
					fin: this.finData
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.productosTotal,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		productosQuery: function cargaProductos() {
			this.continuaData = this.productosQuery.continua_data;
			this.productosTotal = this.productosQuery.productos;
		}
	},
	methods: {
		addNewRow() {
			this.productos.push({
				ID: "",
				Rubro: "",
				Producto: ""
			});
		},

		deleteRow(index, producto) {
			const idx = this.productos.indexOf(producto);
			console.log(idx, index);
			if (idx > -1) {
				this.productos.splice(idx, 1);
			}
		},
		loadMore() {
			this.inicioData += this.paginacion;
			this.finData += this.paginacion;
		},
		loadLess() {
			this.inicioData -= this.paginacion;
			this.finData -= this.paginacion;
		}
	}
};
</script>
